import React from "react";
import './Footer.css';
import Instagram from "../../assets/instagram.png";
import Facebook from "../../assets/facebook.png"

const Footer = () => {
    return(
        <div className="Footer-container">
            <hr/>
            <div className="footer">
                <div className="socials">
                    <a href="https://www.instagram.com/hurricanetraininguk/">
                        <img src={Instagram} alt="" />
                    </a>
                    <a href="https://www.facebook.com/hurricanetraininguk">
                        <img src={Facebook} alt="" />
                    </a>
                </div>
                <div className="logo-f">
                    <div>Hurricane Training</div>
                    
                </div>
            </div>
        </div>
    )
}

export default Footer