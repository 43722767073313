import React from "react";
import './Why.css';
import Header from "../Header/Header";
import Race1 from '../../assets/Race_3.jpeg';
import {motion} from 'framer-motion';
import {Link} from "react-scroll"
const Hero = () => {
    const transition = {type: 'spring', duration : 3}
    const mobile = window.innerWidth<768 ? true: false;
    return (
        <div className="hero" id="why">
            <div className="main">
                
                <div className="hero-text">
                    <div>
                        <span className="stroke-text">Our Service </span>
                    </div>
                </div>
                
                {/* figures */}
                <div className="figures">
                    <div>
                        <span>Youth Classes</span>
                        <span>- All ages welcome</span>
                    </div>
                    <div>
                        <span>Women's classes</span>
                        <span>- Build skills and fitness</span>
                    </div>
                    <div>
                        <span>Adults Mixed classes</span>
                        <span>- Growing our family</span>
                    </div>
                </div>
                <div className="figures">
                    <div>
                        <span>Competition Boxing</span>
                        <span>- If you want to be the best</span>
                    </div>
                    <div>
                        <span>Supporting mental health</span>
                        <span>- Founders of Everyone's fighting something</span>
                    </div>
                    <div>
                        <span>World class coaching</span>
                        <span>- World champion coach</span>
                    </div>
                </div>
            </div> 
            {/* This allows to divide page if theres lots of content */}
            
            
        </div>
    )
}

export default Hero