import React, { useState } from 'react';
import './Header.css';
import Logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
import {Link} from "react-scroll"
const Header = () => {
    const mobile = window.innerWidth<768 ? true: false;
    const [menuOpened, setMenuOpened] = useState(false);
    return (
        <div className='header'>
            <img src={Logo} alt="" className='logo'/>
            {(menuOpened === false && mobile === true)? (
                <div 
                style={{backgroundColor: "red", padding:'0.5rem', borderRadius:'5px'}}
                onClick={()=> setMenuOpened(true)}
                >
                    <img src={Bars} alt='' style={{width: '1.5rem', height: '1.5rem'}}/>
                </div>
            ) : (
            <ul className='header-menu'>
                <li onClick={()=>setMenuOpened(false)}>
                    <Link
                    onClick={()=>setMenuOpened(false)}
                    to="Home"
                    span={true}
                    smooth={true}>Home
                    </Link>
                </li>
                <li onClick={()=>setMenuOpened(false)}><Link
                    onClick={()=>setMenuOpened(false)}
                    to="plans"
                    span={true}
                    smooth={true}>About
                    </Link></li>
                <li onClick={()=>setMenuOpened(false)}><Link
                    onClick={()=>setMenuOpened(false)}
                    to="why"
                    span={true}
                    smooth={true}>Service
                    </Link></li>
                <li onClick={()=>setMenuOpened(false)}>
                    <Link
                    onClick={()=>setMenuOpened(false)}
                    to="footer"
                    span={true}
                    smooth={true}>Links
                    </Link></li>
            </ul>
            )}        
        </div>
    )
}

export default Header