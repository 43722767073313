import React from 'react';
import './Plans.css';
import { programsData } from '../../data/programsData';
const Plans = () => {
    return (
        <div className='Plans' id="plans">
            <div className="plan-categories">
                {programsData.map((program)=>(
                    <div className='category'>
                        <span>{program.heading}</span>
                        <span>{program.details}</span>
                    </div>
                ))}
            </div>
        </div>

    )
}

export default Plans