import React from "react";
import './Hero.css';
import Header from "../Header/Header";
import Race1 from '../../assets/Race_3.jpeg';
import {motion} from 'framer-motion';
import {Link} from "react-scroll"
const Hero = () => {
    const transition = {type: 'spring', duration : 3}
    const mobile = window.innerWidth<768 ? true: false;
    return (
        <div className="hero" id="Home">
            <div className="main">
                <Header/>
                {/* SLOGAN */}
                
                <div className="hero-text">
                    <div>
                        <span className="stroke-text">Hurricane </span>
                    </div>
                    <div>
                        <span>training</span>
                    </div>
                    <div>
                        <span>Fitness and coaching from a world champion</span>
                    </div>
                </div>
                
                {/* figures */}
                <div className="figures">
                    <div>
                        <span>Kids + Adults</span>
                        <span>- All ages welcome</span>
                    </div>
                    <div>
                        <span>Mind + Body</span>
                        <span>- Imrpove your physical + mental health</span>
                    </div>
                    <div>
                        <span>Local</span>
                        <span>- Based in Fleet, Hampshire</span>
                    </div>
                </div>
                {/* Hero buttons */}
                <div className="hero-buttons">
                    <div className='btn'>
                        <form action="https://hurricane.as.me/schedule/a03e83b6">
                            <input className='btn-1' type="submit" value="Book a class" />
                        </form>
                    </div>
                    <buttons className="btn">
                        <Link to="plans" span={true} smooth={true}>
                            Learn More
                        </Link>
                    </buttons>
                </div>
            </div> 
            {/* This allows to divide page if theres lots of content */}
            
            
        </div>
    )
}

export default Hero