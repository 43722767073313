import './App.css';
import Hero from './components/Hero/Hero';
import Plans from './components/Plans/Plans';
import Why from "./components/Why/Why"
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
          <Hero></Hero>
          <Plans></Plans>
          <Why></Why>
          <Footer></Footer>
    </div>
  );
}

export default App;
