export const programsData = [
  {
    heading: "Classes",
    details:
      "Our gym offers a range of classes tailored to all needs. Whether you're looking to compete, start young, or join a welcoming community as a woman or adult, we have a class for you. Our competition boxing classes sharpen skills for the ring, while youth classes focus on teaching fundamentals in a supportive space. Women’s classes offer a safe environment for fitness and skill-building, and our mixed adult classes are open to all levels, ensuring everyone can find their place. Boxing is for everyone, regardless of experience or background.",
  },
  {
    heading: "Everyone's fighting something",
    details:
      "We’re proud to share our charity: Everyone’s Fighting Something, focused on mental health awareness. This initiative reflects our belief that everyone faces battles, whether in the ring or beyond it. Through this, we bring attention to mental health issues, ensuring people have the resources and encouragement they need to overcome struggles.",
  },
  {
    heading: "Our Passion",
    details:
      "Darren, a former world champion kickboxer, is dedicated to teaching and helping others achieve their best. With deep experience and enthusiasm, he brings a wealth of knowledge to every session. His journey from world champion to training future champions is fueled by a desire to give back, making them a motivating force for everyone who steps into the gym.",
  }
];



